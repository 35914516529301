import { Column } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData, Item } from '@smu-chile/pkg-unimarc-hooks'
import { MyAccountFeatureContent } from './components/MyAccountFeatureContent'
import { MyAccountMenu } from './components/MyAccountMenu'
import { useRenderItem } from 'components/MyAccountPage/helpers/useRenderItem'

export const ALLOWED_ORIENTATION = ['Vertical']

export enum BlockTypes {
  FeatureContentItem = 'featureContentItem',
  UnimediosMainBanner = 'unimediosMainBanner'
}

export type MyAccountBlocksProps = {
  items?: Array<Item>
}

export const MyAccountBlocks = ({ items }: MyAccountBlocksProps) => {
  const { render } = useRenderItem('contentType', () => {
    return {
      [BlockTypes.FeatureContentItem]: renderFeatureContentItem,
      [BlockTypes.UnimediosMainBanner]: renderMenuGroupBlock
    }
  })

  const renderFeatureContentItem = (featureContent: Item) => {
    return (
      <MyAccountFeatureContent
        format={featureContent.fields?.itemFormat}
        items={featureContent.fields?.items as Array<Item>}
      />
    )
  }

  const renderMenuGroupBlock = (menuGroupBlock: Item) => {
    if (ALLOWED_ORIENTATION.includes(menuGroupBlock.fields?.orientation)) {
      return (
        <MyAccountMenu
          items={menuGroupBlock.fields?.items as Array<Item>}
          title={menuGroupBlock.fields?.title}
        />
      )
    }

    return null
  }

  return (
    <Column gap={16}>
      {isValidArrayWithData(items) &&
        items.map((item) => {
          return <>{render(item)}</>
        })}
    </Column>
  )
}
