import Head from 'next/head'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { MyAccountPage } from 'components/MyAccountPage'
import { OrdersList } from 'components/OrdersList'

const MyAccount = (): React.ReactElement => {
  return (
    <>
      <Head>
        <title>Mis Compras | Unimarc</title>
      </Head>
      <BigScreen>
        <OrdersList />
      </BigScreen>

      <SmallScreen>
        <MyAccountPage />
      </SmallScreen>
    </>
  )
}

export default MyAccount
